<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';


export default {
  props:{
    profile: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      obj: null,
    };
  },
  validations: {
    obj: {
      name: { required }
    }
  },
  created() {
    this.obj = { id: this.profile.id,
                 name: this.profile.name,
                 riskScore: this.profile.solutionData.RiskLevel,
                 investmentHorizon: this.profile.questionnaire.investmentHorizon,
                 forcedLiquidateRisk: this.profile.questionnaire.forcedLiquidateRisk,
                 investmentStyle: this.profile.questionnaire.investmentStyle,
                 investmentStyleBenchmark: this.profile.questionnaire.investmentStyleBenchmark,
                 riskAttitude: this.profile.questionnaire.riskAttitude,
                 riskAbility: this.profile.questionnaire.riskAbility,
                 riskScenarioAttitude: this.profile.questionnaire.riskScenarioAttitude
                };
  },
  mounted() {
   this.$refs['modal'].show();
  },
  computed: {
    ...mapGetters('riskprofiles', {
      processing: 'processing',
      riskProfiles: 'items'
    })
  },
  methods: {
    getinvStyleComp() {
      if(this.obj.investmentStyle === 4) {
        return 1;
      } else if(this.obj.investmentStyleBenchmark === "C8 USGG10YR TR Benchmark") {
        return 2;
      } else if(this.obj.investmentStyleBenchmark === "S&P Balanced Equity and Bond") {
        return 3;
      }
    },
    resetModal() {
       this.$refs['modal'].hide();
       this.$emit('close');
    }
  }
};
</script>

<template>
  <b-modal
    size="lg"
    :scrollable="true"
    title-class="font-18" 
    :hide-header="true"
    :hide-header-close="true"
    ref="modal"
    @hidden="resetModal"
  >
  
    <h2 class="mt-2 mb-4"><b>{{ obj.name }}</b></h2>

    <h3 class="d-inline my-2 mr-2">Risk Score: {{ obj.riskScore }}</h3>
    <p class="d-inline text-muted ml-4 mb-3" v-if="obj.riskScore === 1">(Low Risk Level)</p>
    <p class="d-inline text-muted ml-4 mb-3" v-if="obj.riskScore === 2">(Low-Moderate Risk Level)</p>
    <p class="d-inline text-muted ml-4 mb-3" v-if="obj.riskScore === 3">(Moderate Risk Level)</p>
    <p class="d-inline text-muted ml-4 mb-3" v-if="obj.riskScore === 4">(Moderate-High Risk Level)</p>
    <p class="d-inline text-muted ml-4 mb-3" v-if="obj.riskScore === 5">(High Risk Level)</p>
    
    <div class="card cardc8 mt-4"  style="position: relative;">
      <div class="card-content m-3">
        <div class="card-body">
          <div class="row">
            <span class="text-center">
              <h5 class="q-square-box">1</h5>
            </span>
            <h5 class="col">What investment period is expected?</h5>
          </div>
          <p size="lg" class="mt-2" v-if="obj.investmentHorizon === 1">1-5 years</p>
          <p size="lg" class="mt-2" v-if="obj.investmentHorizon === 2">3-7 years</p>
          <p size="lg" class="mt-2" v-if="obj.investmentHorizon === 3">5-10 years</p>
          <p size="lg" class="mt-2" v-if="obj.investmentHorizon === 4">10-15 years</p>
          <p size="lg" class="mt-2" v-if="obj.investmentHorizon === 5">Over 15 years</p>
        </div>

        <div class="card-body">
          <div class="row">
            <span class="text-center">
              <h5 class="q-square-box">2</h5>
            </span>
            <h5 class="col">How likely is a 50% redemption over that investment horizon?</h5>
          </div>
          <div class=" d-sm-block">
            <span style="display:inline-block"  >
              <b-form-radio-group
                button-variant="outline-secondary"
                size="lg"
                class="mt-2"
                name="forcedLiquidateRisk"
                v-model="obj.forcedLiquidateRisk"
                buttons
              >
                <b-form-radio v-model="obj.forcedLiquidateRisk" :value="1" :disabled="obj.forcedLiquidateRisk !== 1">1</b-form-radio>
                <b-form-radio v-model="obj.forcedLiquidateRisk" :value="2" :disabled="obj.forcedLiquidateRisk !== 2">2</b-form-radio>
                <b-form-radio v-model="obj.forcedLiquidateRisk" :value="3" :disabled="obj.forcedLiquidateRisk !== 3">3</b-form-radio>
                <b-form-radio v-model="obj.forcedLiquidateRisk" :value="4" :disabled="obj.forcedLiquidateRisk !== 4">4</b-form-radio>
                <b-form-radio v-model="obj.forcedLiquidateRisk" :value="5" :disabled="obj.forcedLiquidateRisk !== 5">5</b-form-radio>
              </b-form-radio-group>
              <span style="display:block" class="small">
                <span class="float-left">Not at all likely</span>
                <span class="float-right">Extremely likely</span>
              </span>
            </span>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <span class="text-center">
              <h5 class="q-square-box">3</h5>
            </span>
            <h5 class="col">What is most important when thinking about the portfolio?</h5>
          </div>
          <p class="mt-2" size="lg" v-if="getinvStyleComp() === 1">
            Stability -- Build on a Tactical Asset Allocation model selecting for stable returns. The allocation penalises excess performance and underperformance equally.
          </p>
          <p class="mt-2" size="lg" v-if="getinvStyleComp() === 2">
            Income -- Prefer a mixed allocation enabling synthetic income. The portfolio seeks to return long term government yields plus 4%.
          </p>
          <p class="mt-2" size="lg" v-if="getinvStyleComp() === 3">
            Balance -- Allow a diverse portfolio tailored to risk appetite. Depending on your risk profile, the solution will aim to track either Conservative, Moderate or Growth benchmarks that invest in equities and government bonds.
          </p>
        </div>

        <div class="card-body">
          <div class="row">
            <span class="text-center">
              <h5 class="q-square-box" v-if="obj.investmentStyle !== 5">4</h5>
              <h5 class="q-square-box" v-if="obj.investmentStyle === 5">5</h5>
            </span>
            <h5 class="col">What is the attitude towards investment risk?</h5>
          </div>
          <p size="lg" class="mt-2" v-if="obj.riskAttitude === 1">Low risk investments with secure income wanted.</p>
          <p size="lg" class="mt-2" v-if="obj.riskAttitude === 2">Moderate risk for more capital gain acceptable.</p>
          <p size="lg" class="mt-2" v-if="obj.riskAttitude === 3">Slightly higher risk to achieve higher yield acceptable.</p>
          <p size="lg" class="mt-2" v-if="obj.riskAttitude === 4">Higher risks accepted to seek high capital gains.</p>
          <p size="lg" class="mt-2" v-if="obj.riskAttitude === 5">Any risks accepted to obtain highest possible capital gains.</p>
        </div>

        <div class="card-body">
          <div class="row">
            <span class="text-center">
              <h5 class="q-square-box" v-if="obj.investmentStyle !== 5">5</h5>
              <h5 class="q-square-box" v-if="obj.investmentStyle === 5">6</h5>
            </span>
            <h5 class="col">What is the ability to take investment risk?</h5>
          </div>
          <p size="lg" class="mt-2" v-if="obj.riskAbility === 1">Drawdown up to 10% before I would have to take action.</p>
          <p size="lg" class="mt-2" v-if="obj.riskAbility === 2">Drawdown up to 20% before I would have to take action.</p>
          <p size="lg" class="mt-2" v-if="obj.riskAbility === 3">Drawdown up to 40% before I would have to take action.</p>
        </div>

        <div class="card-body">
          <div class="row">
            <span class="text-center">
              <h5 class="q-square-box" v-if="obj.investmentStyle !== 5">6</h5>
              <h5 class="q-square-box" v-if="obj.investmentStyle === 5">7</h5>
            </span>
            <h5 class="col">Imagine the investment yields 6% per year, but it just lost 20%. What is the reaction?</h5>
          </div>
          <p size="lg" class="mt-2" v-if="obj.riskScenarioAttitude === 1">Awful. Losing 20% is too much.</p>
          <p size="lg" class="mt-2" v-if="obj.riskScenarioAttitude === 2">Unhappy. Uncomfortable with losing 20%.</p>
          <p size="lg" class="mt-2" v-if="obj.riskScenarioAttitude === 3">Okay. It is accepted that risk is required to generate returns.</p>
          <p size="lg" class="mt-2" v-if="obj.riskScenarioAttitude === 4">Disappointed. Hoping for a higher return.</p>
          <p size="lg" class="mt-2" v-if="obj.riskScenarioAttitude === 5">Sanguine. Risk and return expectations are higher.</p>
        </div>
      </div>
    </div>
   
    <div slot="modal-footer" class="d-flex float-right">  
      <b-button variant="outline-primary" @click="resetModal" :disabled="processing">
        <i class="fa fa-spin fa-spinner mr-1" v-if="processing"></i>  OK
      </b-button>
    </div>
  </b-modal>
</template>