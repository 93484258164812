<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from 'vuex';


export default {
  props:{
    profile: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      obj: null,
      submitted: false,
      invalidName: false
    };
  },
  validations: {
    obj: {
      name: { required }
    }
  },
  created() {
    this.obj = { id: this.profile.id, name: this.profile.name };
  },
  mounted() {
   this.$refs['modal'].show();
  },
  computed: {
    ...mapGetters('riskprofiles', {
      processing: 'processing',
      riskProfiles: 'items'
    })
  },
  methods: {
    ...mapActions('riskprofiles', {
      updateProfile: 'update',
    }),
    onHide() {
       this.$refs['modal'].hide();
       this.$emit('close');
    },
    onOk() {
      console.log('onOk');
      this.tryToSave();
    },
    tryToSave() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.invalidName = false;
        const o = this.riskProfiles.find(x => x.name.toLowerCase().trim() === this.obj.name.toLowerCase().trim() && x.id !== this.obj.id);
        if(o) {
          this.invalidName = true;
          return;
        }
        
        const { id, name } = this.obj;
        this.updateProfile({ id, name }).then(()=>{
          this.onHide();
        });
      }
    }
  }
};
</script>

<template>
  <b-modal
    title-class="font-18" 
    hide-footer 
    :hide-header-close="true"
    no-close-on-backdrop
    no-close-on-esc
    ref="modal"
  >
    <div slot="modal-title">
      Profile Edit
    </div>
    
    <div class="form-horizontal">
      <div class="form-group" v-if="invalidName">
        <div class="alert alert-warning">
          Name is not unique!
        </div>
      </div>
      <div class="form-group">
        <label for="profileName">Name</label>
        <input
          v-model="obj.name"
          type="text"
          class="form-control"
          id="profileName"
          placeholder="Enter Name"
          :class="{ 'is-invalid': submitted && $v.obj.name.$error }"
        />
        <div v-if="submitted && $v.obj.name.$error" class="invalid-feedback">
          <span v-if="!$v.obj.name.required">Name is required.</span>
        </div>
      </div>
    </div>
   
    <div class="mt-4 d-flex justify-content-between">  
      <b-button variant="outline-secondary" @click="onHide">Cancel</b-button>
      <b-button variant="outline-primary" @click="onOk" :disabled="processing">
        <i class="fa fa-spin fa-spinner mr-1" v-if="processing"></i>  Save
      </b-button>
    </div>
  </b-modal>
</template>