<script>

export default {
  components: {
  
  },
  props: {
    obj:{
       type: Object,
       required: true
    }
  },
  mounted() {
   this.$refs['modal'].show();
  },
  destroyed(){
    
  },
  computed :{
  
  },
  data() {
    return {
    
    };
  },
  methods: {
    onHide() {
      this.$refs['modal'].hide();
       this.$emit('close');
    },
  }
};
</script>

<template>
  <b-modal
      title-class="font-18" 
      hide-footer 
      :hide-header-close="true"
      no-close-on-backdrop
      no-close-on-esc
      ref="modal"
    >
    <div slot="modal-title">
        TAX LOSS HARVEST
    </div>
    <div class="">
       <table class="table  table-sm table-borderless">
         <thead>
            <tr>
              <th class="col-6  p-2"></th>
              <th class="col-3 text-right p-2">Tax Loss</th>
              <th class="col-3 text-right p-2">Restricted Until</th>
            </tr>
           
          </thead>
          <tbody>
            <template  v-for="(rec, $index) in obj.summary.taxLossSummaryItems">
              <template>
                <tr :key="$index.toString() + 'r'">
                  <td class="col-6 font-bold  px-2"> <b>{{rec.name}}</b></td>
                  <td class="col-3  font-bold text-right  px-2"> <b>{{rec.amount.toLocaleString('en-US')}}</b></td>
                  <td class="col-3  font-bold text-right  px-2">
                    <b>
                    <span v-if="!rec.restrictionDate" class="text-muted">None</span>
                    <span v-if="rec.restrictionDate" class="text-muted"> {{rec.restrictionDate | moment('L')}}</span>
                    </b>
                  </td>
                </tr>
                <tr v-for="(trade, $subindex) in rec.trades" :key=" $index + $subindex.toString() + 's'">
                  <td colspan="100" class="text-right  px-2">
                  <span class="mb-2 text-muted">
                    {{trade.dateTime | moment('L')}} {{trade.direction}} {{trade.size}} at {{trade.price}} 
                  </span>
                  </td>
                </tr>
                <tr :key="$index.toString() + 'r2'">
                  <td class="border-bottom" colspan="1000"></td>
                </tr>
              </template>
            </template>
            
          </tbody>
        </table>
    </div>
    <div class="mt-4 d-flex justify-content-between">  
      <b-button variant="outline-secondary" @click="onHide">Cancel</b-button>
      
    </div>
  </b-modal>
</template>
